

export const blockData=[
  {
    title: "Architecture and concept",
    content: "Creation and adaptation of architectural concepts, documentation preparation",
    path: "viewConstruction=Architecture and concept",
    blocks: [
      { block_name: "Architecture", block_content: "Concept creation, architectural design – from idea to object operation" },
      { block_name: "Foreign concepts adaptation", block_content: "Preparing project and work documentation based on the architectural/ structural solution" },
      { block_name: "Design projects", block_content: "For residential and public facilities. Author supervision, 3D modelling, VR visualization" },
      { block_name: "Project and work documentation", block_content: "Passing the expertise, author supervision, construction supervision" },    
    ]
  },
  { title: "Urban and master planning",
    content: "Development of territorial plans with account of transport infrastructure, zoning and sustainable development",
    path: "viewConstruction=Urban and master planning",
    blocks: [
      { block_name: "Documentation preparation", block_content: "Defining the overall territory development strategy, context shaping, functional zoning, transport infrastructure layout and other aspects of urban planning. Preparation of set of documents for the expertise" },
      { block_name: "Urban planning analysis", block_content: "Analyzing the territory potential: study of urban, zonal and territorial restrictions. Architectural land functional concepts of a town, district, social and residential infrastructure and etc." },
      { block_name: "Design", block_content: "Developing designed terrain solutions in Civil 3D, calculating groundworks scale, choice of surfaces, materials" },
      { block_name: "Landscaping", block_content: "Choosing small architectural forms, calculating the vegetation, designing plant layout" },
      { block_name: "Environment", block_content: "Creating comfortable urban environment, including parks, gardens, lighting system, water supply and drainage" },      
    ]
  },
  { title: "Structures",
    content: "Development of construction structures and technical solutions",
    path: "viewConstruction=Structures",
    blocks: [
      { block_name: "Structural solutions", block_content: "Preparing necessary calculations to support the chosen solutions" },
      { block_name: "Checking calculations", block_content: "Audit of design solutions made by other design companies, issuing recommendations" },
      { block_name: "Mathematic modelling", block_content: "Analyzing wind loads with account of existing development and evaluating pedestrian comfort" },
      { block_name: "Concept analysis", block_content: "Analyzing structural schemes and options with account of ongoing architectural form creation at the early stage of project development. This also includes the evaluation of central stiffness core geometry" },     
    ]
  },
  { title: "Utilities",
    content: "Development of utilities and MEP systems for buildings and constructions",
    path: "viewConstruction=Utilities",
    blocks: [
      { block_name: "MEP development", block_content: "Water supply and drainage, power supply, electrical lighting, HVAC, telecommunications and automation" },
      { block_name: "Utilities design", block_content: "Heat supply, gas supply, household water drainage, comprehensive scheme of utilities" },    
    ]
  },
  { title: "Geotechnics",
    content: "Study and application of geological and geotechnical characteristics of object location",
    path: "viewConstruction=Geotechnics",
    blocks: [
      { block_name: "Underground premises planning", block_content: "Applying special methods of construction. Project od underground area development for unique and highly complicated objects" },
      { block_name: "Geotechnical analysis", block_content: "Analyzing and preparing recommendations to optimize project and exploration documentation. Structural non-linear 3D analysis of complex structural nods" },
      { block_name: "Aerodynamics study", block_content: "Conducting comprehensive aerodynamics study of an object (distribution of wind and snow loads, wind comfort in pedestrian areas, resonance vortex excitation and construction aerodynamics instability check)" },
      { block_name: "Structural solutions calculations", block_content: "Calculating basic and alternative structural solutions for general and specific loads combinations (with account of construction stages, calculating seismic loads, progressive rupture, carst funnels appearance and etc)" },
      { block_name: "Geotechnical and technical monitoring", block_content: "Developing the program. Ensuring work quality, cooperation of construction process participants, objects and people safety" },
    ]
  },
  { title: "Science",
    content: "Application of scientific research and knowledge in design and construction solutions",
    path: "viewConstruction=Science",
    blocks: [
      { block_name: "Scientific and technical support", block_content: "Providing calculation support of design and construction objects of any complexity and on all stages. Project solutions expertise (analysis and recommendations issuing)" },
      { block_name: "Comprehensive aerodynamics study", block_content: "Distribution of wind and snow loads, wind comfort in pedestrian areas, resonance vortex excitation and construction aerodynamics instability check" },
      { block_name: "Alternative calculations for structural solutions", block_content: "Calculating general and specific loads: with account of construction stages, calculating seismic loads, progressive rupture, carst sinkholes appearance and etc." },    
    ]
  },
  { title: "Technologies",
    content: "Vast range of works in various directions of technological solutions",
    path: "viewConstruction=Technologies",
    blocks: [
      { block_name: "Vast range of technological solutions directions", block_content: "Food and beverage facilities, parkings and carwashes, shops and malls, sport facilities, hotels, medical and educational premises, garbage disposal and vertical transport, different spheres of industrial technology" },
      { block_name: "Concept creation", block_content: "Presenting technological functional zoning and layout solutions, technological flows planning. Placing equipment in Revit models" },
      { block_name: "Project documentation", block_content: "Preparing documentation for the expertise based on the presented technological functional concept" },    
    ]
  },
  { title: "IT",
    content: "Application of modern digital tools and methods to optimize design and project management",
    path: "viewConstruction=IT",
    blocks: [
      { block_name: "Digitalization of construction processes", block_content: "Developing software, infrastructure and methods in digital environment for utilities design, development and construction" },
      { block_name: "Standards creation", block_content: "Creating a knowledge base, which includes standards, codes and instructions for transparency of the information modelling process" },
      { block_name: "Software development", block_content: "Developing solutions to automatize and optimize information modelling processes" },
      { block_name: "Embedding, training, consulting and audit", block_content: "Sharing our vast experience and helping produce a qualitative digital product" },
      // { block_name: "Концептуальный анализ", block_content: "Конструктивных схем и вариантов с учетом поиска архитектурной формы на начальной стадии развития проекта, включая оценку геометрии центрального кара жесткости" },
      // { block_name: "Концептуальный анализ", block_content: "Конструктивных схем и вариантов с учетом поиска архитектурной формы на начальной стадии развития проекта, включая оценку геометрии центрального кара жесткости" },
    ]
  },
]