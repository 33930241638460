const tableData = [
  {
    title: "Moscow Architectural Award",
    nomination: "Best Architectural and Urban Planning Solution for Transport Infrastructure Object",
    prize: "Winner",
    year: "/_2024/",
  },
  {
    title: "Real Estate Property Awards",
    nomination: "Architectural concepts",
    prize: "Finalist",
    year: "/_2024/",
   },
   {
    title: "100 best objects of Russia",
    nomination: "Best structural solutions",
    prize: "Winner",
    year: "/_2023/",
   },
   {
    title: "Real estate market records",
    nomination: "Club house #1 of Moscow",
    prize: "Winner",
    year: "/_2023/",
  },
  {
    title: "Leader of the Russian construction complex",
    nomination: "Best design and survey company",
    prize: "Winner",
    year: "/_2022/",
   },
   {
    title: "Best employers of Russia rating",
    nomination: "Construction and real estate",
    prize: "TOP 10",
    year: "/_2022/",
   },
   {
    title: "European property Awards",
    nomination: "Best Architecture. Best Landscaping. Best Interior",
    prize: "Winner",
    year: "/_2022/",
   },
   {
    title: "Glass in Architecture",
    nomination: "Office and business building",
    prize: "Silver diploma",
    year: "/_2022/",
   },
   {
    title: "Moscow Architectural Committee Awards",
    nomination: "Best Architectural and Urban Planning Solution for Transport Object",
    prize: "Winner",
    year: "/_2022/",
   },
   {
    title: "REPA Awards",
    nomination: "Company Reputation. Design Bureau",
    prize: "Winner",
    year: "/_2022/",
   },
   {
    title: "Urban Awards",
    nomination: "Best Premium-Class Residential Skyscraper in Moscow",
    prize: "Winner",
    year: "/_2022/",
   },
   {
    title: "Urban Awards",
    nomination: "Best Premium-Class Apartment Complex in Moscow",
    prize: "Winner",
    year: "/_2021/",
   },
   {
    title: "World Travel Awards",
    nomination: "Best European point of attraction in enotourism",
    prize: "Winner",
    year: "/_2021/",
   },
   {
    title: "World Architecture Festival (WAF)",
    nomination: "Residential, Re-Use, Masterplanning",
    prize: "Winner",
    year: "/_2019/",
   },
];

export default tableData;