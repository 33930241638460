const Host = process.env.REACT_APP_SERVER_HOST;

const popupsData = [
  // {
  //   id: 1,
  //   title: "Marks Group",
  //   coordinates: [55.783, 37.5804],
  //   image: "../assets/projectFoto/office.jpg",
  //   buttonText: "Смотреть контакты",
  // },
  {
    id: 2,
    title: "GosNII GA",
    coordinates: [55.977355, 37.388843],
    image: `${Host}/images/projectFoto/gosnii.png`,
    buttonText: "",
  },
  {
    id: 3,
    title: "Residential Complex “Badaevsky”",
    coordinates: [55.748888, 37.557268],
    image: `${Host}/images/projectFoto/badaevsky.png`,
    buttonText: "",
  },
  {
    id: 4,
    title: "Office and commercial building with integrated underground parking А23",
    coordinates: [55.787644, 37.332501],
    image: `${Host}/images/projectFoto/a23.png`,
    buttonText: "",
  },
  {
    id: 5,
    title: "Office and commercial building with integrated underground parking А24",
    coordinates: [55.787644, 37.3329],
    image: `${Host}/images/projectFoto/a24.png`,
    buttonText: "",
  },
  {
    id: 6,
    title: "Office and commercial building with integrated underground parking А27",
    coordinates: [55.787644, 37.3322],
    image: "",
    buttonText: "",
  },
  {
    id: 7,
    title: "Office and commercial building ТО4",
    coordinates: [55.7879, 37.3328],
    image: `${Host}/images/projectFoto/to4.png`,
    buttonText: "",
  },
  {
    id: 8,
    title: "Residential Complex “Eniteo”",
    coordinates: [55.677666, 37.586904],
    image: `${Host}/images/projectFoto/eniteo.png`,
    buttonText: "",
  },
  {
    id: 9,
    title: "B11",
    coordinates: [55.7879, 37.3331],
    image: `${Host}/images/projectFoto/b11.png`,
    buttonText: "",
  },
  {
    id: 10,
    title: "B12",
    coordinates: [55.7879, 37.3325],
    image: `${Host}/images/projectFoto/b12.png`,
    buttonText: "",
  },
  {
    id: 11,
    title: "Residential Complex “Vishnevy sad”",
    coordinates: [55.722355, 37.532196],
    image: `${Host}/images/projectFoto/vishevyi_sad.png`,
    buttonText: "",
  },
  {
    id: 12,
    title: "Savvinskaya",
    coordinates: [55.734572, 37.565767],
    image: `${Host}/images/projectFoto/savinskay.png`,
    buttonText: "",
  },
  {
    id: 13,
    title: "Mixed-use complex “Chkalov”",
    coordinates: [55.755859, 37.659461],
    image: `${Host}/images/projectFoto/chkalov.png`,
    buttonText: "",
  },
  {
    id: 14,
    title: "Hotel complex with apartments “Cosmo”",
    coordinates: [55.745600, 37.638782],
    image: `${Host}/images/projectFoto/cosmo.png`,
    buttonText: "",
  },
  {
    id: 15,
    title: "Shopping mall “Khorosho”",
    coordinates: [55.777105, 37.523716],
    image: `${Host}/images/projectFoto/horosho.png`,
    buttonText: "",
  },
  {
    id: 16,
    title: "Mixed-use complex “Slava”",
    coordinates: [55.780335, 37.580939],
    image: `${Host}/images/projectFoto/slava.png`,
    buttonText: "",
  }, 
  {
    id: 17,
    title: "Residential Complex “Rodnoy gorod”",
    coordinates: [55.664091, 37.546264],
    image: `${Host}/images/projectFoto/rodnoy_gorod.png`,
    buttonText: "",
  },
  {
    id: 18,
    title: "The State Academic Bolshoi Theatre of Russia",
    coordinates: [55.760221, 37.618561],
    image: `${Host}/images/projectFoto/big_theater.png`,
    buttonText: "",
  },
  {
    id: 19,
    title: "Artificial structure",
    coordinates: [54.777900, 38.138505],
    image: "",
    buttonText: "",
  },
  {
    id: 20,
    title: "Mixed-use complex with apartments RC “Nagatinskaya poyma”",
    coordinates: [55.692756, 37.682251],
    image: `${Host}/images/projectFoto/nagatinskay_poima.png`,
    buttonText: "",
  },
  {
    id: 21,
    title: "Mixed-use complex with underground parking “Level Nagatinskaya”",
    coordinates: [55.682432, 37.637749],
    image: `${Host}/images/projectFoto/level_nagatinskay.png`,
    buttonText: "",
  },
  {
    id: 22,
    title: "Residential Complex “Prime Park”",
    coordinates: [55.792108, 37.540757],
    image: `${Host}/images/projectFoto/prime_park.png`,
    buttonText: "",
  },
  {
    id: 23,
    title: "Residential Complex “Bolshaya semerka”",
    coordinates: [55.810370, 37.702544],
    image: `${Host}/images/projectFoto/big_7.png`,
    buttonText: "",
  },
  {
    id: 24,
    title: "Transport hub “Timiryazevskaya”",
    coordinates: [55.819245, 37.576317],
    image: `${Host}/images/projectFoto/tpu_timiryazevskay.png`,
    buttonText: "",
  },
  {
    id: 25,
    title: "Residential Complex “Le Dome”",
    coordinates: [55.742869, 37.605221],
    image: `${Host}/images/projectFoto/le_dome.png`,
    buttonText: "",
  },
];

export default popupsData;




// const Host = process.env.REACT_APP_SERVER_HOST;

// const popupsData = [
//   // {
//   //   id: 1,
//   //   title: "Marks Group",
//   //   coordinates: [37.5804, 55.783],
//   //   image: "../assets/projectFoto/office.jpg",
//   //   buttonText: "Смотреть контакты",
//   // },
//   {
//     id: 2,
//     title: "ГОСНИИ ГА",
//     coordinates: [37.388843, 55.977355],
//     image: `${Host}/images/projectFoto/gosnii.png`,
//     buttonText: "",
//   },
//   {
//     id: 3,
//     title: "ЖК «Бадаевский»",
//     coordinates: [37.557268, 55.748888],
//     image: `${Host}/images/projectFoto/badaevsky.png`,
//     buttonText: "",
//   },
//   {
//     id: 4,
//     title: "Офисно-деловое здание со встроенной подземной автостоянкой А23",
//     coordinates: [ 37.332501, 55.787644],
//     image: `${Host}/images/projectFoto/a23.png`,
//     buttonText: "",
//   },
//   {
//     id: 5,
//     title: "Офисно-деловое здание со встроенной подземной автостоянкой А24",
//     coordinates: [37.3329, 55.787644],
//     image: `${Host}/images/projectFoto/a24.png`,
//     buttonText: "",
//   },
//   {
//     id: 6,
//     title: "Офисно-деловое здание со встроенной подземной автостоянкой А27",
//     coordinates: [37.3322, 55.787644],
//     image: "",
//     buttonText: "",
//   },
//   {
//     id: 7,
//     title: "Офисно-деловое здание ТО4",
//     coordinates: [37.3328, 55.7879],
//     image: `${Host}/images/projectFoto/to4.png`,
//     buttonText: "",
//   },
//   {
//     id: 8,
//     title: "ЖК Eniteo",
//     coordinates: [37.586904, 55.677666],
//     image: `${Host}/images/projectFoto/eniteo.png`,
//     buttonText: "",
//   },
//   {
//     id: 9,
//     title: "B11",
//     coordinates: [37.3331, 55.7879],
//     image: `${Host}/images/projectFoto/b11.png`,
//     buttonText: "",
//   },
//   {
//     id: 10,
//     title: "B12",
//     coordinates: [37.3325, 55.7879],
//     image: `${Host}/images/projectFoto/b12.png`,
//     buttonText: "",
//   },
//   {
//     id: 11,
//     title: "ЖК «Вишнёвый сад»",
//     coordinates: [37.532196, 55.722355],
//     image: `${Host}/images/projectFoto/vishevyi_sad.png`,
//     buttonText: "",
//   },
//   {
//     id: 12,
//     title: "Саввинская",
//     coordinates: [37.565767, 55.734572],
//     image: `${Host}/images/projectFoto/savinskay.png`,
//     buttonText: "",
//   },
//   {
//     id: 13,
//     title: "МФК «Чкалов»",
//     coordinates: [37.659461, 55.755859],
//     image: `${Host}/images/projectFoto/chkalov.png`,
//     buttonText: "",
//   },
//   {
//     id: 14,
//     title: "Гостиничный комплекс с апартаментами «Космо»",
//     coordinates: [37.638782, 55.745600],
//     image: `${Host}/images/projectFoto/cosmo.png`,
//     buttonText: "",
//   },
//   {
//     id: 15,
//     title: "ТЦ «Хорошо»",
//     coordinates: [37.523716, 55.777105],
//     image: `${Host}/images/projectFoto/horosho.png`,
//     buttonText: "",
//   },
//   {
//     id: 16,
//     title: "МФК SLAVA",
//     coordinates: [37.580939, 55.780335],
//     image: `${Host}/images/projectFoto/slava.png`,
//     buttonText: "",
//   }, 
//   {
//     id: 17,
//     title: "ЖК «Родной город»",
//     coordinates: [37.546264, 55.664091],
//     image: `${Host}/images/projectFoto/rodnoy_gorod.png`,
//     buttonText: "",
//   },
//   {
//     id: 18,
//     title: "Государственный Академический Большой Театр России",
//     coordinates: [37.618561, 55.760221],
//     image: `${Host}/images/projectFoto/big_theater.png`,
//     buttonText: "",
//   },
//   {
//     id: 19,
//     title: "Искусственное сооружение",
//     coordinates: [38.138505, 54.777900],
//     image: "",
//     buttonText: "",
//   },
//   {
//     id: 20,
//     title: "МФК с апартаментами ЖК «Нагатинская пойма»",
//     coordinates: [37.682251, 55.692756],
//     image: `${Host}/images/projectFoto/nagatinskay_poima.png`,
//     buttonText: "",
//   },
//   {
//     id: 21,
//     title: "МФК с подземной автостоянкой Level Нагатинская",
//     coordinates: [37.637749, 55.682432],
//     image: `${Host}/images/projectFoto/level_nagatinskay.png`,
//     buttonText: "",
//   },
//   {
//     id: 22,
//     title: "ЖК PRIME PARK",
//     coordinates: [37.540757, 55.792108],
//     image: `${Host}/images/projectFoto/prime_park.png`,
//     buttonText: "",
//   },
//   {
//     id: 23,
//     title: "ЖК «Большая Семёрка»",
//     coordinates: [37.702544, 55.810370],
//     image: `${Host}/images/projectFoto/big_7.png`,
//     buttonText: "",
//   },
//   {
//     id: 24,
//     title: "ТПУ «Тимирязевская»",
//     coordinates: [37.576317, 55.819245],
//     image: `${Host}/images/projectFoto/tpu_timiryazevskay.png`,
//     buttonText: "",
//   },
//   {
//     id: 25,
//     title: "ЖК LE DOME",
//     coordinates: [37.605221, 55.742869],
//     image: `${Host}/images/projectFoto/le_dome.png`,
//     buttonText: "",
//   },
//   // {
//   //   id: 26,
//   //   title: "Архитектурная концепция жилого комплекса",
//   //   coordinates: [37.523716, 55.777105],
//     // image: "../assets/office.jpg",
//     // buttonText: "Смотреть контакты",
//   // },
//     // {
//   //   id: 27,
//   //   title: "Многофункциональный офисно-торговый центр",
//   //   coordinates: [37.565767, 55.734572],
//     // image: "../assets/office.jpg",
//     // buttonText: "Смотреть контакты",
//   // },
//     // {
//   //   id: 28,
//   //   title: "МФК жилой застройки с подземной автостоянкой ЖК Южнопортовая",
//   //   coordinates: [37.332501, 55.787644],
//     // image: "../assets/office.jpg",
//     // buttonText: "Смотреть контакты",
//   // },
//     // {
//   //   id: 29,
//   //   title: "Жилой дом с подземной автостоянкой",
//   //   coordinates: [37.332501, 55.787644],
//     // image: "../assets/office.jpg",
//     // buttonText: "Смотреть контакты",
//   // },
//   // {
//   //   id: 30,
//   //   title: "",
//   //   coordinates: [37.332501, 55.787644],
//   //   image: "",
//   //   buttonText: "",
//   // },
//    // {
//   //   id: 31,
//   //   title: "Жилой дом С3 в микрорайоне Заречье",
//     // coordinates: [37.557268, 55.748888],
//     // image: "../assets/office.jpg",
//     // buttonText: "Смотреть контакты",
//   // },
// ];

// export default popupsData;