import React, { useEffect, useState } from "react";
// import InputMask from 'react-input-mask';
// import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import styles from "./CompanyFormContacts.module.css";

const CompanyFormContacts = ({
  handleMouseEnter,
  handleMouseLeave,
  handleMouseHover,
}) => {
  const [isFocused1, setIsFocused1] = useState(false);
  const [value1, setValue1] = useState("");
  const [isFocused2, setIsFocused2] = useState(false);
  const [value2, setValue2] = useState("");
  const [isFocused3, setIsFocused3] = useState(false);
  const [value3, setValue3] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [isMatching, setIsMatching] = useState(true);
  const [isConsentGiven, setIsConsentGiven] = useState(false);
  const [phoneClicked, setPhoneClicked] = useState(false);
  const [inputLineColor, setInputLineColor] = useState('#959DA6');
  const [formData, setFormData] = useState({ phone: "" });

  // const [showError, setShowError] = useState(false);
 
    const Host = process.env.REACT_APP_SERVER_HOST;
 

    function handleFocus(event) {
      const name = event?.target?.name;
      if (name === "name") {
        setIsFocused1(true);
        setPhoneClicked(false);
      } else if (name === "phone") {
        setIsFocused2(true);
        setPhoneClicked(true);
        setInputLineColor('#FF7F6A');
      } else if (name === "task") {
        setIsFocused3(true);
        setPhoneClicked(false);
        setInputLineColor('#959DA6'); 
      }
    }

  function handleBlur(event) {
    const name = event?.target?.name;
    if (!event.target.value && name !== "phone") {
      event.target.placeholder = "";
      if (name === "name") {
        setIsFocused1(false);
      } else if (name === "task") {
        setIsFocused3(false);
      }
    } else {
      if (name === "name") {
        setIsFocused1(true);
      } else if (name === "task") {
        setIsFocused3(true);
      }

      if (name === "phone") {
        setIsFocused2(false);
        setInputLineColor('#959DA6');
      }
    }
  }
  

  function handleChange(event) {
    const name = event?.target?.name;
    const value = event?.target?.value;
    if (name === "name") {
      setValue1(value);
      if (!value) {
        setIsFocused1(false);
      }
    } else if (name === "phone") {
      setValue2(value);
      if (!value) {
        setIsFocused2(false);
        setInputLineColor('#959DA6'); 
      }
    } else if (name === "task") {
      setValue3(value);
      if (!value) {
        setIsFocused3(false);
      }
    } 
  }

  function handleSubmit(event) {
    event.preventDefault(); 
    if (!isConsentGiven) {
      return;
    }
    const formData = {
      name: value1,
      phone: value2,
      task: value3,
      consentGiven: isConsentGiven,
    };
    fetch(`${Host}/api-mail/submitForm`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then(response => {
        if (response.ok) {
          setValue1("");
          setValue2("");
          setValue3("");
          setIsConsentGiven(false);   
        } else {
          console.error('Form submission failed');
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }
      // const handlePaste = (event) => {
      //   event.preventDefault(); 
      //   const pastedData = event.clipboardData.getData("text");
      //   const cleanedValue = pastedData.replace(/\D/g, "");
      //   if (cleanedValue.length >= 10) {
      //     const formattedValue = `+7 (${cleanedValue.slice(-10, -7)}) ${cleanedValue.slice(-7, -4)}-${cleanedValue.slice(-4, -2)}-${cleanedValue.slice(-2)}`;
      //     setValue2(formattedValue);
      //     setIsValid(true);
      //     setIsMatching(true);
      //   } else {
      //     setIsValid(false);
      //     setIsMatching(false);
      //     setInputLineColor("#959DA6");
      //   }
      // };
      
      
  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
      .react-tel-input .country-list::-webkit-scrollbar {  /* Ширина полосы прокрутки */
        width: 6px;      
      }
      .react-tel-input .country-list::-webkit-scrollbar-thumb {  /* полоса прокрутки */
        background: #959DA6; 
        border-radius: 8px;
        height: 20px;   
      }
      .react-tel-input .country {
        font-family: var(--font-raleway); 
        font-size: 16px; 
        color: #1D2E43; 
      }
       .react-tel-input .country:hover {   /* Фон при наведении */
        background-color: #FFBFB5 !important;        
      }     
    `;
    document.head.appendChild(style);
    return () => {
    document.head.removeChild(style);
    };
  }, []);
  
  return (
    <div className={styles.form_left_wrapper}>
      <div className={styles.border_line_con}>
      <div className={styles.form_left_main}>
        <div className={styles.title}>Contacts</div>
        <span className={styles.span_text}>
        Our specialists will answer your questions
        </span>
        <form className={styles.form_form_wrapper} onSubmit={handleSubmit}>
          <div className={`${styles.group} some-unique-class`}>
            <label
              className={`${styles.label1} ${
                isFocused1 ? styles.focused1 : " "
              }`}
              onMouseOver={handleMouseEnter}
              onMouseOut={handleMouseLeave}
            >
               <input
                type="text"
                name="name"
                placeholder=""
                value={value1}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                maxLength="30"
                size="65"
                autoComplete="off"
                className={styles.input_name_form_contacts}                
              />
              <div className={styles.line}></div>
              <span className={styles.placeholder}>Name</span>
            </label>
          </div>
          <div className={styles.group}>
            <label
             className={`${styles.label1} ${isFocused2 ? styles.focused1 : " "}`}
             onMouseOver={handleMouseEnter}
             onMouseOut={handleMouseLeave}
             >
              <PhoneInput
                id="phone-input"
                country='ae'
                name="phone"
                placeholder=""
                value={value2}
                onBlur={handleBlur}
                onChange={(phone) => {
                  setValue2(phone);
                  if (!phone) {
                    setIsFocused2(false);
                    setInputLineColor('#959DA6');
                  }
                }}
                onFocus={handleFocus}
                // onPaste={handlePaste}
                  inputProps={{
                    name: 'phone',
                    autoComplete: "off"
                  }}
                className={`${styles.input_tel_form_contacts} ${isFocused2 ? styles.focused : ''}`}
                  containerStyle={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingTop: '1.5rem',
                    backgroundColor: 'transparent',  
                 }}  
                inputStyle={{ 
                  backgroundColor: 'transparent',                  
                  border: 'none',
                  outline: 'none',
                  fontFamily: 'var(--font-raleway)',
                  fontStyle: 'normal',
                  fontWeight: 'var(--fw-400)',
                  fontSize: '16px',
                  lineHeight: '16px',
                  position: 'relative',
                  flex: 1,
                }}      
                  buttonStyle={{
                    backgroundColor: 'transparent',
                    alignSelf: 'center',
                    border: 'none',
                    marginTop: '20px',
                  }}
                  dropdownStyle={{
                    backgroundColor: '#EDEDED', // Фон выпадающего списка
                    border: '1px solid #C0C5CB', 
                    zIndex: 998,
                    borderRadius: '0.5rem',                    
                  }}                           
              />
              <div className={styles.line}
               style={{ backgroundColor: inputLineColor }}            
                ></div>                
              {!isValid && value2.length > 0 && ( <span className={styles.error_line}>Invalid phone number</span>)}
              {/* <span className={styles.placeholder}>Phone</span> */}
            </label>
          </div>
          <div className={styles.group}>
            <label
              className={`${styles.label1} ${
                isFocused3 ? styles.focused1 : " "
              }`}
              onMouseOver={handleMouseEnter}
              onMouseOut={handleMouseLeave}
            >
              <input
                type="text"
                name="task"
                placeholder=""
                value={value3}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                maxLength="300"
                size="55"
                autoComplete="off"
                className={styles.input_task_form_contacts}               
              />
              <div className={styles.line}></div>
              <span className={styles.placeholder}>Question</span>
            </label>
          </div>
          <div className={styles.agreement}>
            <label
              onMouseOver={handleMouseEnter}
              onMouseOut={handleMouseLeave}
              >
              <input
                type="checkbox"
                id="checkbox"
                className={`${styles.checkbox} checkbox-input`}
                checked={isConsentGiven}
                onChange={(e) => setIsConsentGiven(e.target.checked)}
                onMouseOver={handleMouseEnter}
                onMouseOut={handleMouseLeave}
              />
              <p className={styles.agreement_label_text_block}>I accept the
              <a href="./Information"
             >&nbsp;<u>privacy&nbsp;policy</u></a></p>
            </label>
          </div>
          <button
          className={`${styles.button_hover10}
          ${!isValid || !value2 || !value1 || !value3 || !isConsentGiven ? styles.disabled : ""}`}
          disabled={!isValid || !value2 || !value1 || !value3 || !isConsentGiven}
          type="submit"
          id="submit-button"
          onMouseOver={handleMouseEnter}
          onMouseOut={handleMouseLeave}
          // onMouseHover={handleMouseHover}
           >
            <p className={styles.button_name10}>Send</p>
          </button>
        </form>
      </div>
    </div>
    </div>
  );
};

export default CompanyFormContacts;
