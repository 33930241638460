import React from "react";
import styles from "./Information.module.css";

const Information = () => { 

  return (
    <div className={styles.information_wrapper}>
      <h1 className={styles.information_h1}>
        Privacy policy (including cookies)
      </h1>
      <p className={styles.information_p}>
        Hereby, in&nbsp;accordance with the&nbsp;Federal Law No.&nbsp;152-FZ&nbsp;dd&nbsp;07/27/2006
        "On&nbsp;Personal Data», you&nbsp;confirm your consent to&nbsp;the&nbsp;processing of&nbsp;your personal
        data by&nbsp;LLC&nbsp;"MARKS ENGINEERING", legal address: 125040, Moscow, 3rd&nbsp;Yamskogo Polya&nbsp;str.,&nbsp;20,
        bld.&nbsp;1, including the&nbsp;Marksgroup Company Group (hereinafter – Marksgroup).
      </p>
      <p className={styles.information_p}>
        Personal data means all information (including cookies) related directly or indirectly to&nbsp;you,
        including, but&nbsp;not&nbsp;limited&nbsp;to: last name, first name, patronymic, date of&nbsp;birth, contact phone number,
        email&nbsp;address, IP&nbsp;address, information about the&nbsp;browser used, access time to&nbsp;any&nbsp;software, site,
        service, the&nbsp;Marksgroup proposal, cookies.
      </p>
      <p className={styles.information_p}>
        With this consent you confirm that you are informed that the&nbsp;processing of&nbsp;personal data
        refers to&nbsp;actions with personal data defined in&nbsp;the Federal&nbsp;Law No.&nbsp;152-FZ of&nbsp;07/27/2006 "On&nbsp;Personal&nbsp;Data",
        namely: collection, recording, systematization, accumulation, storage, clarification (update, modification),
        extraction, usage, transfer (provision, access), blocking, removal of&nbsp;personal data
        performed by&nbsp;automation tools of&nbsp;Marksgroup.
      </p>
      <p className={styles.information_p}>
        With this consent, you confirm that you are informed that the processing of&nbsp;personal data
        provided by&nbsp;you may be carried out for&nbsp;the&nbsp;following purpose: to&nbsp;fulfill the&nbsp;binding
        obligations of&nbsp;Marksgroup under orders, contracts and other obligations before you.
        For this purpose, Marksgroup implements actions aimed at&nbsp;implementation of&nbsp;the&nbsp;following tasks:
      </p>
      <p className={styles.information_p}>
        – identification of&nbsp;the&nbsp;party for&nbsp;the&nbsp;Service provision;
      </p>
      <p className={styles.information_p}>
        – provision of&nbsp;personalized Services to&nbsp;the&nbsp;User;
      </p>
      <p className={styles.information_p}>
        – provision of&nbsp;opportunity for&nbsp;the&nbsp;User to&nbsp;use the&nbsp;software,
        features and services, including account creation and authorization;
      </p>
      <p className={styles.information_p}>
        – improvement of&nbsp;the&nbsp;Services quality and development of&nbsp;new ones;
      </p>
      <p className={styles.information_p}>
        – conduction of&nbsp;statistical and other research based on&nbsp;depersonalized data;
      </p>
      <p className={styles.information_p}>
        – provision of&nbsp;the&nbsp;User's personal data to&nbsp;copyright holders,
        distributors or resellers of&nbsp;the&nbsp;software for&nbsp;the&nbsp;purpose of&nbsp;registering the&nbsp;software
        in&nbsp;the&nbsp;name of&nbsp;the&nbsp;User or the&nbsp;organization whose interests the&nbsp;User represents;
      </p>
      <p className={styles.information_p}>
        – provision of&nbsp;safe and convenient usage functionality, effective information display for the&nbsp;Website Users;
      </p>
      <p className={styles.information_p}>
        – fulfillment of&nbsp;contracts obligations with the&nbsp;User, the&nbsp;User's employer, the&nbsp;User's counterparty;
      </p>
      <p className={styles.information_p}>
        – collection of&nbsp;feedback from the&nbsp;User (including via WhatsApp and Telegram, VKontakte social networks, Zoom);
      </p>
      <p className={styles.information_p}>
        – registration of&nbsp;Website Users for various events;
      </p>
      <p className={styles.information_p}>
        – identification of&nbsp;security threats to&nbsp;software, features and services,
        users, Marksgroups and/or third parties, including risk assessment of&nbsp;the&nbsp;User
        when concluding contracts for software, features and services usage.
      </p>
      <p className={styles.information_p}>
        – sending marketing information to&nbsp;the&nbsp;User (including advertising newsletters)
        using any means of&nbsp;communication;
      </p>
      <p className={styles.information_p}>
        This consent applies to&nbsp;the&nbsp;following personal data: surname, first name, patronymic,
        date of&nbsp;birth, contact phone number, information about the&nbsp;company, position,
        fax number, cell phone number, e-mail&nbsp;address, information about the&nbsp;country, city,
        area of&nbsp;residence, IP&nbsp;address, information about the browser used, access time to&nbsp;any software,
        site, service, the&nbsp;Marksgroup proposal, cookies.
      </p>
      <p className={styles.information_p}>
        The validity period of&nbsp;your consent is limited by&nbsp;the&nbsp;period required to&nbsp;achieve
        the&nbsp;purpose of&nbsp;personal data processing, unless another period of&nbsp;personal data
        storage is&nbsp;established by&nbsp;an&nbsp;applicable law. However, you have the&nbsp;right to&nbsp;revoke
        this consent at&nbsp;any&nbsp;time by&nbsp;sending a&nbsp;written notification to&nbsp;the&nbsp;address: 125040,
        Moscow, 3rd&nbsp;Yamskogo&nbsp;Polya&nbsp;str.,&nbsp;20, bld.&nbsp;1, to&nbsp;"Marksgroup Company Group",
        marked "withdrawal of&nbsp;consent to&nbsp;personal data processing". Your personal data
        will be&nbsp;deleted by&nbsp;Marksgroup within&nbsp;30&nbsp;days after receiving this notification.
      </p>
      <p className={styles.information_p}>
        Marksgroup guarantees the&nbsp;observance of&nbsp;the&nbsp;following rights of&nbsp;the&nbsp;personal data subject:
        the&nbsp;right to&nbsp;receive information on&nbsp;which personal data of&nbsp;the&nbsp;personal data owner
        is&nbsp;stored by&nbsp;Marksgroup; the&nbsp;right to&nbsp;delete, clarify or correct personal data stored
        by&nbsp;Marksgroup; other rights established by&nbsp;the&nbsp;current legislation of&nbsp;the&nbsp;Russian Federation.
      </p>
      <p className={styles.information_p}>
        The User personal data is&nbsp;stored for&nbsp;the&nbsp;entire duration of&nbsp;User
        agreements with&nbsp;this&nbsp;User and for&nbsp;10&nbsp;years after their termination,
        unless the&nbsp;personal data has been personally revoked by&nbsp;the&nbsp;User.
      </p> 
    </div>
  );
};

export default Information;


